<template>
  <ag-grid-vue
    style="width: 100%; height: 400px;"
    class="ag-theme-alpine"
    :gridOptions="gridOptions"
    @grid-ready="onGridReady"
    :columnDefs="columnDefs"
    :rowData="rowData"
    :rowSelection="rowSelection"
    :setQuickFilter="updateSearchQuery"
    suppressDragLeaveHidesColumns
    @selection-changed="onSelectionChanged"
    suppressMovableColumns
    checkboxSelection="true"
  >
  </ag-grid-vue>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import { AgGridVue } from "ag-grid-vue";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import assetImg from "../../../components/Table/components/assetImg.vue";
import iconEditCheck from "../../../components/Table/components/iconEditCheck.vue";
import iconDeleteCheck from "../../../components/Table/components/iconDeleteCheck.vue";
import moment from "moment";
import { mapGetters } from "vuex";

import { toShowAccountingPrice } from "../../../util/accountingPrice";

//  Table Components
import CancelButton from "./CellFramework/CancelButton.vue";

export default {
  emits: ["selected-assets", "cancel-action"],
  data() {
    return {
      columnDefs: null,
      gridApi: null,
      gridColumnApi: null,
      gridOptions: {
        rowHeight: 50,
        defaultColDef: {
          resizable: true,
        },
      },
      testData: [{}, {}, {}],
    };
  },
  props: {
    rowSelection: { required: false, default: "none" },
    page: { type: Number },
    assetData: { default: () => [] },
    searchText: {
      type: String,
    },
    otherColumn: [],
  },
  beforeMount() {},
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
    this.loopHeaderFreeColumn();
  },
  computed: {
    ...mapGetters({
      isRoleLower: "isRoleLower",
      permissionStatus: "permissionStatus",
    }),

    hideCancelColumn() {
      const routeName = this.$route.name;
      if (routeName === "activate-asset" || routeName === "deactivate-asset") {
        return false;
      }
      return true;
    },

    rowData() {
      return this.assetData.map((asset, index) => {
        let dataTable = {
          index: (this.page - 1) * 100 + index + 1,
          id_asset: asset.id_asset,
          id_fixed_asset: asset.id_fixed_asset,
          name: asset.name ? asset.name : "-",
          description: asset.description ? asset.description : "-",
          note_acc: asset.note_acc ? asset.note_acc : "-",
          code_acc: asset.code_acc ? asset.code_acc : "-",
          name_acc: asset.name_acc ? asset.name_acc : "-",
          category: asset.categories ? asset.categories.name : "-",
          sub_category: asset.sub_category ? asset.sub_category.name : "-",
          location_building: asset.location_buildings
            ? asset.location_buildings.name
            : "-",
          location_floor: asset.location_floors
            ? asset.location_floors.name
            : "-",
          location_department: asset.location_departments
            ? asset.location_departments.name
            : "-",
          location_zone: asset.location_zones ? asset.location_zones.name : "-",
          note: asset.note ? asset.note : "-",
          id_qrcode: this.qrCode(asset),
          depreciation_status: {
            status: this.dpStatusTranslate(asset.depreciation_status),
          },
          purchase_date: asset.purchase_date
            ? this.momentDay(asset.purchase_date)
            : "-",
          purchase_price: asset.purchase_price
            ? toShowAccountingPrice(asset.purchase_price)
            : "-",
          start_date: asset.start_date ? this.momentDay(asset.start_date) : "-",
          expire_date: asset.expire_date
            ? this.momentDay(asset.expire_date)
            : "-",
          useful_life: asset.useful_life ? asset.useful_life : "-",
          scrap_value: asset.scrap_value
            ? toShowAccountingPrice(asset.scrap_value)
            : "-",
          acc_depreciation: asset.acc_depreciation
            ? toShowAccountingPrice(asset.acc_depreciation)
            : "-",
          net_book: asset.net_book
            ? toShowAccountingPrice(asset.net_book)
            : "-",
          depreciation_date: asset.depreciation_date
            ? this.momentDay(asset.depreciation_date)
            : "-",
          prior_depreciation: asset.prior_depreciation
            ? toShowAccountingPrice(asset.prior_depreciation)
            : "-",
          prior_net_book: asset.prior_net_book
            ? toShowAccountingPrice(asset.prior_net_book)
            : "-",

          deactivate_dates:
            asset.pause_assets.length > 0
              ? this.momentDay(asset.pause_assets[0]?.deactivate_at)
              : null,
          activate_dates:
            asset.pause_assets.length > 0
              ? this.momentDay(asset.pause_assets[0]?.activate_at)
              : null,
          id_pause_asset:
            asset.pause_assets.length > 0
              ? asset.pause_assets[0].id_pause_asset
              : null,
          write_off_date: asset.write_off_date
            ? this.momentDay(asset.write_off_date)
            : "-",
          profit_loss: asset.profit_loss
            ? toShowAccountingPrice(asset.profit_loss)
            : "-",
          write_off_amount: asset.write_off_amount
            ? toShowAccountingPrice(asset.write_off_amount)
            : "-",
          residual_value: asset.residual_value
            ? toShowAccountingPrice(asset.residual_value)
            : "-",
          asset_sector: asset.sector?.name || "-",
          asset_branch: asset.branch?.name || "-",
          insurance_start: asset.custom_start_time
            ? this.momentDay(asset.custom_start_time)
            : "-",
          insurance_end: asset.custom_end_time
            ? this.momentDay(asset.custom_end_time)
            : "-",
          vendor: asset.vendor?.name || "-",
          deed_title: asset.deed_title || "-",
          location_land: asset.location_land || "-",
          deed_page: asset.deed_page || "-",
          land_area: asset.land_area || "-",
          land_status: asset.land_status
            ? this.landStatus(asset.land_status)
            : "-",
          image_url: asset.image_url
            ? { data: asset.image_url.url }
            : { data: require("@/image/Not-regis-qr-code.png") },
          edit: { id_user: asset.id_asset },
          delete: { ...asset, can_delete: true },
        };
        const assetDetail = asset.asset_details;
        assetDetail.forEach((val) => {
          dataTable[`other_${val.column_no}`] = val.value || "-";
        });
        return dataTable;
      });
    },
  },
  components: {
    AgGridVue,
    CancelButton,
    assetImg,
    iconDeleteCheck,
    iconEditCheck,
  },
  watch: {
    searchText() {
      this.updateSearchQuery(this.searchText);
    },
    rowData() {
      this.loopHeaderFreeColumn();
    },
  },
  methods: {
    dpStatusTranslate(status) {
      switch (status) {
        case "active":
          return "Active";
        case "expire":
          return "Fully Depreciated";
        case "deactivate":
          return "Deactivate";
        case "write off":
          return "Written off / Disposed";
        default:
          return "-";
      }
    },
    landStatus(status) {
      switch (status) {
        case "mortgage":
          return this.$t("depreciation.mortgage");
        case "burden free":
          return this.$t("depreciation.burden_free");
        default:
          return "-";
      }
    },

    qrCode(asset) {
      if (this.$route.params.summaryId) {
        return asset?.qrcode;
      } else {
        return asset?.qrcode?.id_qrcode
          ? asset?.qrcode?.id_qrcode
          : this.$t("home.not_reg");
      }
    },
    loopHeaderFreeColumn() {
      let columnDefs = [
        {
          headerName: this.$t("count.order"),
          field: "index",
          headerCheckboxSelection:
            this.rowSelection === "multiple" ? true : false,
          headerCheckboxSelectionFilteredOnly: true,
          checkboxSelection: this.rowSelection !== "none" ? true : false,
          maxWidth: 110,
          suppressSizeToFit: true,
          cellClass: "text-center",
        },
        {
          headerName: this.$t("home.fixedas_num"),
          field: "id_fixed_asset",
          sortable: true,
          suppressSizeToFit: true,
        },
        {
          headerName: this.$t("depreciation.deed_title"),
          field: "deed_title",
          sortable: true,
          minWidth: 200,
        },
        {
          headerName: this.$t("depreciation.location_land"),
          field: "location_land",
          sortable: true,
          minWidth: 200,
        },
        {
          headerName: this.$t("depreciation.deed_page"),
          field: "deed_page",
          sortable: true,
          minWidth: 200,
        },
        {
          headerName: this.$t("depreciation.land_area"),
          field: "land_area",
          sortable: true,
          minWidth: 200,
          cellClass: "text-end",
        },
        {
          headerName: this.$t("depreciation.purchase_date"),
          field: "purchase_date",
          sortable: true,
          minWidth: 200,
          cellClass: "text-center",
        },
        {
          headerName: this.$t("depreciation.purchase_price"),
          field: "purchase_price",
          sortable: true,
          minWidth: 200,
          cellClass: "text-end",
        },
        {
          headerName: this.$t("depreciation.land_status"),
          field: "land_status",
          sortable: true,
          minWidth: 200,
          cellClass: "text-center",
        },
        {
          headerName: this.$t("depreciation.deed_image"),
          field: "image_url",
          maxWidth: 130,
          suppressSizeToFit: true,
          cellRendererFramework: "assetImg",
          cellClass: "text-center",
        },
      ];

      let column = this.otherColumn;
      for (const col of column) {
        let header = {
          headerName: col.name,
          field: `other_${col.column_no}`,
          cellRenderer: (params) => {
            return params.value || "-";
          },
          suppressSizeToFit: true,
        };
        columnDefs.push(header);
      }
      columnDefs = columnDefs.concat([
        {
          headerName: this.$t("btn.edit"),
          field: "edit",
          cellRendererFramework: "iconEditCheck",
          maxWidth: 130,
          suppressSizeToFit: true,
          cellClass: "text-center",
        },
        {
          headerName: this.$t("btn.del_all"),
          field: "delete",
          cellRendererFramework: "iconDeleteCheck",
          maxWidth: 130,
          suppressSizeToFit: true,
          cellClass: "text-center",
        },
      ]);

      this.columnDefs = columnDefs;
    },
    onGridReady() {
      this.gridApi = this.gridOptions.api;
      this.gridColumnApi = this.gridOptions.columnApi;
      // this.gridColumnApi.autoSizeAllColumns();
    },
    momentDay(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    getSelectedRowData() {
      let selectedNodes = this.gridApi.getSelectedNodes();
      let selectedData = selectedNodes.map((node) =>
        this.assetData.find(
          (asset) => asset.id_fixed_asset === node.data.id_fixed_asset
        )
      );
      return selectedData;
    },

    onSelectionChanged(event) {
      const selectedNodes = event.api.getSelectedNodes();
      const selectedData = selectedNodes.map((node) => {
        return node.data;
      });
      this.$emit("selected-assets", selectedData);
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
      this.gridApi.refreshCells();
    },
    cancelActionDate() {
      this.$emit("cancel-action");
    },
  },
};
</script>

<style scoped>
* >>> .ag-header-cell-label {
  justify-content: center;
}
* >>> .ag-header-row-column {
  background: transparent linear-gradient(180deg, #007afe 0%, #013f81 100%);
  color: white;
}
</style>
